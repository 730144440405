import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from '../guards/AuthGuard';

import Loadable from '../layouts/fleet-layout/loadable/Loadable';
import FavoriteDetails from '../views/cargo/favorite/FavoriteDetails';
import MapView from '../views/cargo/mapa/MapView';
import MapViewFlota from '../views/fleet/mapa/MapView';
import PrivateMarketplace from '../views/marketplace/PrivateMarketplace';
import OrderDetail from '../views/fleet/maintenanceOrder/components/OrderDetail';
import { NavigateTo } from '../utils/relatedToPermissions';
import { modules } from '../constants/permissions';
import useAuth from '../hooks/useAuth';
import UserProfile from '../views/profile/components/UserProfile';
import ProfileCreate from '../views/Settings/components/ProfileCreate';
import AddUser from '../views/Settings/components/AddUser';
import Plans from '../views/Settings/components/Plans';
import HistoricTravels from '../views/Settings/components/HistoricTravels';
import Account from '../views/Settings/components/Account';
import RolesCreate from '../views/Settings/components/RolesCreate';
import { OsProvider } from '../views/fleet/serviceOrder/context/OsContext';
import { OtProvider } from '../views/fleet/components/context/OtContext';
import MapLayout from '../views/fleet/MapOT/MapLayout';
import Score from '../views/fleet/rating/Score';
import LoadScore from '../views/fleet/rating/LoadScore';
import LoadsPanelControl from '../views/cargo/panelControl/LoadsPanelControl';
import KPIPanel from '../views/ControlTower/KPIPanel';
import ViewDetailIncident from '../views/fleet/MapOT/Incidents/ViewDetailIncident';

/* ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
const WelcomeLayout = Loadable(lazy(() => import('../layouts/welcome-layout/WelcomeLayout')));
const FleetLayout = Loadable(lazy(() => import('../layouts/fleet-layout/FleetLayout')));
const CargoLayout = Loadable(lazy(() => import('../layouts/cargo-layout/CargoLayout')));
const FollowLayout = Loadable(lazy(() => import('../layouts/follow-layout/FollowLayout')));
const MarketplaceLayout = Loadable(
  lazy(() => import('../layouts/marketplace-layout/MarketplaceLayout')),
);
const MaintenanceLayout = Loadable(
  lazy(() => import('../layouts/maintenance-layout/MaintenanceLayout')),
);
const ResourcesLayout = Loadable(lazy(() => import('../layouts/resources-layout/ResourcesLayout')));
const WorkflowLayout = Loadable(lazy(() => import('../layouts/workflow-layout/WorkflowLayout')));
const SettingLayout = Loadable(lazy(() => import('../layouts/setting-layout/Setting-layout')));
const HelpCenterLayout = Loadable(lazy(() => import('../layouts/help-center/HelpCenter')));
const BayLayout = Loadable(lazy(() => import('../layouts/bay-layout/BayLayout')));
const ControlTowerLayout = Loadable(
  lazy(() => import('../layouts/control-tower/ControlTowerLayout')),
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));

const DetallePlanes = Loadable(lazy(() => import('../views/plan/PlanDetails/PlanDetails')));
const FormularioContacto = Loadable(lazy(() => import('../views/plan/ContactUs/ContactUs')));

/* ****Pages***** */
const Welcome = Loadable(lazy(() => import('../views/welcome/Welcome')));

/* ***Pages -> Modulo de Flota*** */
const FleetDashboard = Loadable(lazy(() => import('../views/fleet/dashboard/Dashboard')));
const FollowDashboard = Loadable(lazy(() => import('../views/follow/dashboard/Dashboard')));
const FleetList = Loadable(lazy(() => import('../views/fleet/fleet/FleetList')));
const AssetImportComponent = Loadable(
  lazy(() => import('../views/fleet/dashboard/components/AssetImportComponent')),
);
const OutsourcedList = Loadable(lazy(() => import('../views/fleet/outsourced/Outsourced')));
const OutsourcedAssignment = Loadable(
  lazy(() => import('../views/fleet/outsourced/AssignmentDetails/Admin')),
);
const ManageAssets = Loadable(lazy(() => import('../views/fleet/ManageAssets/ManageAssets')));
const VehicleCreate = Loadable(lazy(() => import('../views/fleet/fleet/components/VehicleCreate')));
const TrailerCreate = Loadable(lazy(() => import('../views/fleet/fleet/components/TrailerCreate')));
const DriverCreate = Loadable(lazy(() => import('../views/fleet/fleet/components/DriverCreate')));
const OtherAssetCreate = Loadable(
  lazy(() => import('../views/fleet/fleet/components/OtherAssetCreate')),
);
const Plan = Loadable(lazy(() => import('../views/fleet/plan/Plan')));
const IncidentsView = Loadable(lazy(() => import('../views/fleet/MapOT/Incidents/IncidentsView')));
const NewIncident = Loadable(
  lazy(() => import('../views/fleet/MapOT/Incidents/NewIncident/NewIncident')),
);

const NewPlan = Loadable(lazy(() => import('../views/fleet/plan/NewPlan')));
const NewMilestone = Loadable(lazy(() => import('../views/fleet/plan/NewMilestone')));
const ServiceOrder = Loadable(lazy(() => import('../views/fleet/serviceOrder/ServiceOrderList')));
const ServiceOrderView = Loadable(lazy(() => import('../views/fleet/serviceOrder/OrderView')));
const ServiceOrderViewToApprove = Loadable(
  lazy(() => import('../views/fleet/serviceOrder/DetailOrderApprove')),
);
const OSContactToApprove = Loadable(lazy(() => import('../views/fleet/serviceOrder/Contacto')));
// const NewServiceOrderView = Loadable(lazy(() => import('../views/fleet/serviceOrder/NewServiceOrder')));
const NewServiceOrderView = Loadable(lazy(() => import('../views/fleet/OS/OSFormAdmin')));
const EditServiceOrderView = Loadable(lazy(() => import('../views/fleet/OS/OSFormEdit')));
// const NewTransportOrder = Loadable(lazy(() => import('../views/fleet/workOrder/NewTransportOrder')));
const NewWorkOrder = Loadable(lazy(() => import('../views/fleet/OT/OTFormAdmin')));
const NewMap = Loadable(lazy(() => import('../views/fleet/newMap/NewMapView')));
// const ServiceOrderCreate = Loadable(lazy(() => import('../views/fleet/serviceOrder/ServiceOrderCreate')));
// const ServiceOrderDetail = Loadable(lazy(() => import('../views/fleet/serviceOrder/ServiceOrderDetail')));
// const Documents = Loadable(lazy(() => import('../views/fleet/documents/DocumentOrderList')));
const Documents = Loadable(lazy(() => import('../views/fleet/documents/AdminV2')));
// const Viaje = Loadable(lazy(() => import('../views/fleet/viaje/Pedido')));
const FleetAssign = Loadable(lazy(() => import('../views/fleet/assign/Assign')));
const OrderView = Loadable(lazy(() => import('../views/cargo/orderView/OrderView')));
const FleetPanelControl = Loadable(
  lazy(() => import('../views/fleet/panelControl/FleetPanelControl')),
);
const WorkOrder = Loadable(lazy(() => import('../views/fleet/workOrder/WorkOrder')));
const WorkOrderDetail = Loadable(lazy(() => import('../views/fleet/workOrder/WorkOrderDetail')));
const ExpensesView = Loadable(lazy(() => import('../views/fleet/workOrder/ExpensesView')));
const WorkOrderEdit = Loadable(lazy(() => import('../views/fleet/components/EditTravel')));
const Seguimiento = Loadable(lazy(() => import('../views/fleet/workOrder/Seguimiento')));
const ActiveCalendar = Loadable(lazy(() => import('../views/fleet/calendar/ActiveCalendar')));
const MaintenanceOrderDashboard = Loadable(
  lazy(() => import('../views/fleet/maintenanceOrder/Dashboard')),
);
const CreateMaintenanceOrders = Loadable(
  lazy(() => import('../views/fleet/maintenanceOrder/components/CreateMaintenanceOrders')),
);

/* ***Pages -> Modulo de Mantenimiento*** */
const MaintenanceDashboard = Loadable(
  lazy(() => import('../views/maintenance/dashboard/Dashboard')),
);
const MaintenanceOrderCreate = Loadable(
  lazy(() => import('../views/maintenance/dashboard/MaintenanceOrderCreate')),
);
const MaintenanceHistory = Loadable(
  lazy(() => import('../views/maintenance/maintenanceHistory/MaintenanceHistory')),
);
const VehicleReport = Loadable(
  lazy(() => import('../views/maintenance/vehicleReport/VehicleReport')),
);
const BasicReport = Loadable(lazy(() => import('../views/maintenance/basicReport/BasicReport')));
const MaintenancePlansDetails = Loadable(
  lazy(() =>
    import('../views/maintenance/dashboard/maintenancePlansCreate/MaintenancePlansCreate'),
  ),
);
const MaintenancePlansEdit = Loadable(
  lazy(() =>
    import('../views/maintenance/dashboard/maintenancePlansCreate/MaintenancePlansCreate'),
  ),
);
const MaintenancePlansCreate = Loadable(
  lazy(() =>
    import('../views/maintenance/dashboard/maintenancePlansCreate/MaintenancePlansCreate'),
  ),
);
const MaintenancePlans = Loadable(
  lazy(() => import('../views/maintenance/dashboard/maintenancePlans/MaintenancePlans')),
);

/* ** Pages -> Modulo Recursos*** */
const ResourcesDashboard = Loadable(lazy(() => import('../views/resources/dashboard/Dashboard')));

/* ** Pages -> Modulo Workflow*** */
const WorkflowDashboard = Loadable(lazy(() => import('../views/workflow/Dashboard')));
const Procedures = Loadable(lazy(() => import('../views/workflow/procedures/Procedures')));
const Processes = Loadable(lazy(() => import('../views/workflow/procesos/Processes')));

const WorkflowProcesoDocumental = Loadable(
  lazy(() => import('../views/workflow/procesoDocumental/Admin')),
);

const WorkflowProcedimientos = Loadable(
  lazy(() => import('../views/workflow/procesoDocumental/Admin')),
);
// const WorkflowAsignarProcedimiento = Loadable(
//   lazy(() => import('../views/workflow/procesoDocumental/Asignacion/Admin'))
// );

// const WorkflowAsignarProcesoDocumental = Loadable(
//   lazy(() => import('../views/workflow/procesoDocumental/Asignacion/Admin'))
// );

const WorkflowProcedimientosAdmin = Loadable(
  lazy(() => import('../views/workflow/procedureTree/ProcedureTree')),
);

const WorkflowPlantillasAdmin = Loadable(
  lazy(() => import('../views/workflow/plantillas/FormsAdministration')),
);
const WorkflowPlantillas = Loadable(lazy(() => import('../views/workflow/plantillas/Crear')));

// const WorkflowDocumentsAdmin = Loadable(
//   lazy(() => import('../views/workflow/administracionDocumentos/Admin'))
// );

// const WorkflowPermissionsAdmin = Loadable(
//   lazy(() => import('../views/workflow/administracionPermisos/Admin'))
// );

const WorkflowForm = Loadable(
  lazy(() => import('../views/workflow/components/WorkflowForm/Admin')),
);

const Assignment = Loadable(
  lazy(() => import('../views/workflow/components/Asignacion/Assignment')),
);

const DocumentManagement = Loadable(
  lazy(() => import('../views/workflow/administracionDocumentos/DocumentManagement')),
);

// No se utiliza eliminar
const WorkflowDocumentacion = Loadable(lazy(() => import('../views/workflow/documentacion/Admin')));

/* ***Pages -> Modulo de Cargas*** */
const CargoDashboard = Loadable(lazy(() => import('../views/cargo/dashboard/Dashboard')));
const CustomerList = Loadable(lazy(() => import('../views/cargo/customer/CustomerList')));
const ImportCustomersComponent = Loadable(
  lazy(() => import('../views/cargo/customer/ImportCustomersComponent')),
);
const CustomerProfile = Loadable(
  lazy(() => import('../views/cargo/customer/CustomerProfile/CustomerProfile')),
);

const Scores = Loadable(lazy(() => import('../views/cargo/customer/CustomerScores/Scores')));

const NewCustomer = Loadable(lazy(() => import('../views/cargo/customer/NewCustomer')));
const CargoView = Loadable(lazy(() => import('../views/cargo/cargo/CargoView')));
const KanbanView = Loadable(lazy(() => import('../views/cargo/kanban/KanbanView')));
const KPIform = Loadable(lazy(() => import('../components/controlPanels/kpiCreator/KPIform')));
const Pedido = Loadable(lazy(() => import('../views/cargo/cargas/CargasTable')));
const PedidoTable = Loadable(lazy(() => import('../views/cargo/pedidos/PedidoTable')));
const PedidosView = Loadable(lazy(() => import('../views/cargo/pedidos/PedidosView')));
const FavoriteView = Loadable(lazy(() => import('../views/cargo/favorite/FavoriteView')));
const Checklist = Loadable(lazy(() => import('../views/cargo/checklist/Checklist')));
const SettingView = Loadable(lazy(() => import('../views/cargo/setting/SettingView')));
const Load = Loadable(lazy(() => import('../views/cargo/load/LoadView')));
const Order = Loadable(lazy(() => import('../views/cargo/order/OrderView')));
const VerDocumentacion = Loadable(lazy(() => import('../views/cargo/verDocumentacion/admin')));
const Calendar = Loadable(lazy(() => import('../views/cargo/calendar/ACalendar')));
const CalendarFlota = Loadable(lazy(() => import('../views/fleet/calendar/ACalendar')));
const ArmadoDeCarga = Loadable(lazy(() => import('../views/cargo/armadoDeCargas/Admin')));
const CompanyProfile = Loadable(
  lazy(() => import('../views/cargo/favorite/CompanyProfile/CompanyProfile')),
);
const Products = Loadable(lazy(() => import('../views/cargo/Producto/Products')));
const ProductsTable = Loadable(lazy(() => import('../views/cargo/Producto/ProductsTable')));
const PanelControl = Loadable(lazy(() => import('../views/cargo/panelControl/LoadsPanelControl')));
const Tarifario = Loadable(lazy(() => import('../views/cargo/tarifario/TarifarioView')));
const NewTarifa = Loadable(lazy(() => import('../views/cargo/tarifario/components/CreateTarifa')));
const EditTarifa = Loadable(lazy(() => import('../views/cargo/tarifario/components/CreateTarifa')));

/* Pages-> Modulo de Marketplace */
const MarketplaceDashboard = Loadable(lazy(() => import('../views/marketplace/dashboard')));
const MarketplaceMisCargas = Loadable(lazy(() => import('../views/marketplace/misCargas')));
const MarketplaceOrderView = Loadable(
  lazy(() => import('../views/marketplace/dashboard/CargaOrderView')),
);
const MarketplaceCustomerProfile = Loadable(
  lazy(() => import('../views/cargo/customer/CustomerProfile/CustomerProfile')),
);
const Contacto = Loadable(
  lazy(() => import('../views/marketplace/dashboard/cargas/DetalleDeCarga/Contacto')),
);

/* Pages-> Configuraciones */
const Setting = Loadable(lazy(() => import('../views/Settings/Settings')));

/* Pages-> Bienvenida */
const SubscriptionPayment = Loadable(lazy(() => import('../views/Pay/SubscriptionPayment')));
const SuccessfulPayment = Loadable(lazy(() => import('../views/Pay/SuccessfulPayment')));
const PaymentDeclined = Loadable(lazy(() => import('../views/Pay/PaymentDeclined')));
/* Ruta externa para ver solo un seguimiento de vieje */
const ViewWithoutLogin = Loadable(lazy(() => import('../views/viewWithoutLogin/ViewWithoutLogin')));
// const SubscriptionPayment = Loadable(lazy(() => import('../views/Pay/SubscriptionPayment')));

/* Pages-> HelpCenter */
const HelpCenter = Loadable(lazy(() => import('../views/Help-center/HelpCenter')));
const KnowPlatform = Loadable(lazy(() => import('../views/Help-center/KnowPlatform/KnowPlatform')));
const CreateCompany = Loadable(
  lazy(() => import('../views/Help-center/CreateCompany/CreateCompany')),
);
const EditCompanyData = Loadable(
  lazy(() => import('../views/Help-center/EditCompanyData/EditCompanyData')),
);
const JoinCompany = Loadable(lazy(() => import('../views/Help-center/JoinCompany/JoinCompany')));
const FrequentQuestions = Loadable(
  lazy(() => import('../views/Help-center/FrequentQuestions/FrequentQuestions')),
);
const ChargeGiverFQ = Loadable(
  lazy(() => import('../views/Help-center/ChargeGiverFQ/ChargeGiverFQ')),
);
const TransportersFQ = Loadable(
  lazy(() => import('../views/Help-center/TransportersFQ/TransportersFQ')),
);
const TutorialsAndVideos = Loadable(
  lazy(() => import('../views/Help-center/TutorialsAndVideos/TutorialsAndVideos')),
);
const AccountInfo = Loadable(lazy(() => import('../views/Help-center/AccountInfo/AccountInfo')));
const TutorialsAndVideosCarriers = Loadable(
  lazy(() => import('../views/Help-center/TutorialsAndVideosCarriers/TutorialsAndVideosCarriers')),
);
const TutorialsAndVideosGiver = Loadable(
  lazy(() => import('../views/Help-center/TutorialsAndVideosGiver/TutorialsAndVideosGiver')),
);

/* ** Pages -> Modulo Bay*** */
const BayDashboard = Loadable(lazy(() => import('../views/Bay/Dashboard/Dashboard')));
const ListBay = Loadable(lazy(() => import('../views/Bay/Bay/ListBay')));
const Bay = Loadable(lazy(() => import('../views/Bay/Bay/Bay')));
const Dock = Loadable(lazy(() => import('../views/Bay/Dock/Dock')));
const ListDock = Loadable(lazy(() => import('../views/Bay/Dock/ListDock')));
const Scheduling = Loadable(lazy(() => import('../views/Bay/Scheduling/Scheduling')));
const Shifts = Loadable(lazy(() => import('../views/Bay/Shifts/Shifts')));
const ABM = Loadable(lazy(() => import('../views/Bay/ABM/ABM')));
const CreateABM = Loadable(lazy(() => import('../views/Bay/ABM/CreateABM')));
const Pendings = Loadable(lazy(() => import('../views/Bay/pendings/PendingView')));

/* ** Torre de control *** */
const ControlTowerDashboard = Loadable(
  lazy(() => import('../views/ControlTower/ControlTowerDashboard')),
);

const MapOT = Loadable(lazy(() => import('../views/fleet/MapOT/MapOT')));

/* ** Administracion ** */
const BackofficeLayout = Loadable(
  lazy(() => import('../layouts/backoffice-layout/backofficeLayout')),
);
const UsersTable = Loadable(lazy(() => import('../views/Backoffice/usersTable')));
const CompaniesTable = Loadable(lazy(() => import('../views/Backoffice/companyTable')));
const RolesTable = Loadable(lazy(() => import('../views/Backoffice/AccessParameters/RolesTable')));
const LoadTypesTable = Loadable(
  lazy(() => import('../views/Backoffice/CargoParameters/LoadTypesTable')),
);
const PackagingTypesTable = Loadable(
  lazy(() => import('../views/Backoffice/CargoParameters/PackagingTypeTable')),
);
const PalletTypesTable = Loadable(
  lazy(() => import('../views/Backoffice/CargoParameters/PalletsTypeTable')),
);
const QueriesTable = Loadable(lazy(() => import('../views/Backoffice/Queries/QueriesTable')));
const PermissionsTable = Loadable(
  lazy(() => import('../views/Backoffice/AccessParameters/Permissions/PermissionsTable')),
);
const PromotionsTable = Loadable(
  lazy(() => import('../views/Backoffice/AccessParameters/PromotionsTable')),
);
const SubscriptionsTable = Loadable(
  lazy(() => import('../views/Backoffice/AccessParameters/Subscriptions/Subscriptions')),
);
const CountriesTable = Loadable(
  lazy(() => import('../views/Backoffice/GeneralParameters/CountryTable')),
);
const PlacesTable = Loadable(
  lazy(() =>
    import(
      '../views/Backoffice/GeneralParameters/ProvincesAndLocalities/ProvincesAndLocalitiesTable'
    ),
  ),
);

const DocumentTypesTable = Loadable(
  lazy(() => import('../views/Backoffice/GeneralParameters/DocumentTypesTable')),
);

const PaymentsTable = Loadable(
  lazy(() => import('../views/Backoffice/GeneralParameters/CurrencyAndPaymentConditionsTable')),
);

const BrandsTable = Loadable(lazy(() => import('../views/Backoffice/FleetParameters/BrandsTable')));

const TruckTypes = Loadable(
  lazy(() => import('../views/Backoffice/FleetParameters/TruckTypeTable')),
);

const BitrenTypes = Loadable(
  lazy(() => import('../views/Backoffice/FleetParameters/BitrenTypesTable')),
);

const TrailerTypes = Loadable(
  lazy(() => import('../views/Backoffice/FleetParameters/TrailerTypesTable')),
);

const ContainerTypes = Loadable(
  lazy(() => import('../views/Backoffice/FleetParameters/ContainerTypesTable')),
);

const AccesoriesTable = Loadable(
  lazy(() => import('../views/Backoffice/FleetParameters/AccesoriesTable')),
);

const FeaturesTable = Loadable(
  lazy(() => import('../views/Backoffice/FleetParameters/FeaturesTable')),
);

const LastMileTypesTable = Loadable(
  lazy(() => import('../views/Backoffice/FleetParameters/LastMileTypesTable')),
);
/* ****Routes***** */

const Router = () => {
  const auth = useAuth();
  const p = auth?.allPermissions;
  const { load: l, fleet: f, marketplace: m, workflow: w, controlTower: c } = modules;

  const useAG = (module, individual, collective = null, Component) => {
    return (
      <AuthGuard module={module} permissions={{ individual, collective }}>
        {Component}
      </AuthGuard>
    );
  };

  const router = [
    {
      path: '/',
      element: useAG(null, null, null, <WelcomeLayout />),
      children: [
        { path: '/', element: <Navigate to="/Inicio" /> },
        { path: '/Inicio', exact: true, element: useAG(null, null, null, <Welcome />) },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },

    // {
    //   path: '/torre-de-control',
    //   element: useAG(c, null, null, <ControlTowerLayout />),
    //   children: [
    //     { index: true, element: <Navigate to="inicio" replace /> },
    //     { path: 'inicio', exact: true, element: useAG(c, null, null, <ControlTowerDashboard />) },
    //   ],
    // },

    {
      path: '/flota',
      element: useAG(f, null, null, <FleetLayout />),
      children: [
        { index: true, element: <Navigate to={NavigateTo(auth, f)} replace /> },
        {
          path: 'inicio',
          exact: true,
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_PED, p?.LIST_CAR],
            null,
            <FleetDashboard />,
          ),
        },
        {
          path: 'activos',
          exact: true,
          element: useAG(
            f,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_VEH,
              p?.CRE_VEH,
              p?.EDI_VEH,
              p?.VER_VEH,
              p?.ELI_VEH,
              p?.LIST_CHF,
              p?.CRE_CHF,
              p?.EDI_CHF,
              p?.VER_CHF,
              p?.ELI_CHF,
              p?.LIST_REM,
              p?.CRE_REM,
              p?.EDI_REM,
              p?.VER_REM,
              p?.ELI_REM,
              p?.LIST_ACT,
              p?.CRE_ACT,
              p?.EDI_ACT,
              p?.VER_ACT,
              p?.ELI_ACT,
            ],
            null,
            <FleetList />,
          ),
        },

        {
          path: 'activos/:id',
          exact: true,
          element: useAG(
            f,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_VEH,
              p?.CRE_VEH,
              p?.EDI_VEH,
              p?.VER_VEH,
              p?.ELI_VEH,
              p?.LIST_CHF,
              p?.CRE_CHF,
              p?.EDI_CHF,
              p?.VER_CHF,
              p?.ELI_CHF,
              p?.LIST_REM,
              p?.CRE_REM,
              p?.EDI_REM,
              p?.VER_REM,
              p?.ELI_REM,
              p?.LIST_ACT,
              p?.CRE_ACT,
              p?.EDI_ACT,
              p?.VER_ACT,
              p?.ELI_ACT,
            ],
            null,
            <FleetList />,
          ),
        },
        {
          path: 'activos/importar/:asset',
          exact: true,
          element: useAG(
            f,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_VEH,
              p?.CRE_VEH,
              p?.EDI_VEH,
              p?.VER_VEH,
              p?.ELI_VEH,
              p?.LIST_CHF,
              p?.CRE_CHF,
              p?.EDI_CHF,
              p?.VER_CHF,
              p?.ELI_CHF,
              p?.LIST_REM,
              p?.CRE_REM,
              p?.EDI_REM,
              p?.VER_REM,
              p?.ELI_REM,
              p?.LIST_ACT,
              p?.CRE_ACT,
              p?.EDI_ACT,
              p?.VER_ACT,
              p?.ELI_ACT,
            ],
            null,
            <AssetImportComponent />,
          ),
        },
        {
          path: 'tercerizados',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <OutsourcedList />),
        },
        {
          path: 'tercerizados/asignacion/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <OutsourcedAssignment />),
        },
        {
          path: 'administrarActivos',
          element: useAG(
            f,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_VEH,
              p?.CRE_VEH,
              p?.EDI_VEH,
              p?.VER_VEH,
              p?.ELI_VEH,
              p?.LIST_CHF,
              p?.CRE_CHF,
              p?.EDI_CHF,
              p?.VER_CHF,
              p?.ELI_CHF,
              p?.LIST_REM,
              p?.CRE_REM,
              p?.EDI_REM,
              p?.VER_REM,
              p?.ELI_REM,
            ],
            null,
            <ManageAssets />,
          ),
        },
        {
          path: 'os',
          element: useAG(
            f,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_CAR,
              p?.ACE_CAR,
              p?.ASIG_CAR,
              p?.CANC_CAR,
              p?.CRE_CAR,
              p?.EDI_CAR,
              p?.ELIM_CAR,
            ],
            null,
            <ServiceOrder />,
          ),
        },
        {
          path: 'nueva-os',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_CAR], null, <NewServiceOrderView />),
        },
        {
          path: 'edit-service-order/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.EDI_CAR], null, <EditServiceOrderView />),
        },
        {
          path: 'detallePedido/:id',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.CRE_CAR, p?.EDI_CAR],
            null,
            <OsProvider>
              <ServiceOrderView />,
            </OsProvider>,
          ),
        },
        {
          path: 'asignar/:id',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.CRE_CAR, p?.EDI_CAR],
            null,
            <OsProvider>
              <ServiceOrderView />,
            </OsProvider>,
          ),
        },

        {
          path: 'detalle-pedido-a-aprobar/:id',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.CRE_CAR, p?.EDI_CAR, p?.LIST_CAR, p?.ACE_CAR],
            null,
            <ServiceOrderViewToApprove />,
          ),
        },
        {
          path: 'contacto/:id',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <OSContactToApprove />),
        },
        // { path: 'new-transport-order', element: <NewTransportOrder /> },
        {
          path: 'viajes',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_VIA, p?.CRE_VIA, p?.EDI_VIA, p?.VER_VIA, p?.ELI_VIA],
            null,
            <WorkOrder />,
          ),
        },
        {
          path: 'nuevo-viaje',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_VIA], null, <NewWorkOrder />),
        },
        {
          path: 'nuevo-mapa',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_VIA], null, <NewMap />),
        },

        {
          path: 'viajes-detalles/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_VIA], null, <WorkOrderDetail />),
        },
        {
          path: 'viajes-gastos/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_VIA], null, <ExpensesView />),
        },
        {
          path: 'viajes-editar/:id',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.EDI_VIA],
            null,
            <OtProvider>
              <WorkOrderEdit />
            </OtProvider>,
          ),
        },
        {
          path: 'viajes-detalles/:id/:scroll',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_VIA], null, <WorkOrderDetail />),
        },
        {
          path: 'viajes/calificacion/:id',
          element: useAG(f, [p?.ROOT, p?.VER_VIA], null, <LoadScore />)
        },
        {
          path: 'seguimiento/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <Seguimiento />),
        },
        {
          path: 'organizar',
          element: useAG(
            f,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_VEH,
              p?.VER_VEH,
              p?.LIST_CHF,
              p?.VER_CHF,
              p?.LIST_REM,
              p?.VER_REM,
              p?.LIST_ACT,
              p?.VER_ACT,
            ],
            null,
            <ActiveCalendar />,
          ),
        },
        {
          path: 'orden-mantenimiento',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_OM, p?.CRE_OM, p?.EDI_OM, p?.VER_OM, p?.ELI_OM],
            null,
            <MaintenanceOrderDashboard />,
          ),
        },
        {
          path: 'orden-mantenimiento/nueva-orden',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_OM], null, <CreateMaintenanceOrders />),
        },
        {
          path: 'orden-mantenimiento/editar-orden/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.EDI_OM], null, <CreateMaintenanceOrders />),
        },
        {
          path: 'orden-mantenimiento/orden-detalle/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_OM], null, <OrderDetail />),
        },
        {
          path: 'new-vehicle',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_VEH], null, <VehicleCreate />),
        },
        {
          path: 'edit-vehicle/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.EDI_VEH], null, <VehicleCreate />),
        },
        {
          path: 'new-trailer',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_REM], null, <TrailerCreate />),
        },
        {
          path: 'edit-trailer/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.EDI_REM], null, <TrailerCreate />),
        },
        {
          path: 'new-driver',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_CHF], null, <DriverCreate />),
        },
        {
          path: 'edit-driver/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.EDI_CHF], null, <DriverCreate />),
        },
        {
          path: 'new-asset',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_ACT], null, <OtherAssetCreate />),
        },
        {
          path: 'edit-asset/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.EDI_ACT], null, <OtherAssetCreate />),
        },
        {
          path: 'checklist/:module',
          exact: true,
          element: useAG(
            f,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_CHKLIST,
              p?.VER_CHKLIST,
              p?.CRE_CHKLIST,
              p?.EDI_CHKLIST,
              p?.ELI_CHKLIST,
            ],
            null,
            <Checklist />,
          ),
        },
        {
          path: 'configuracion',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <SettingView />),
        },
        {
          path: 'configuracion/:type',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <SettingView />),
        },
        {
          path: 'mapa',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_CAR_MAPA], null, <MapViewFlota />),
        },

        { path: 'plans', element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <Plan />) },
        {
          path: 'documentacion',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_DOC, p?.CRE_DOC, p?.EDI_DOC, p?.VER_DOC, p?.ELI_DOC],
            null,
            <Documents />,
          ),
        },
        {
          path: 'planificacion',
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_CAR, p?.LIST_VIA, p?.VER_VIA],
            null,
            <CalendarFlota />,
          ),
        },
        {
          path: 'documentacion/:type/:id',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.LIST_DOC], null, <VerDocumentacion />),
        },
        { path: 'assign', element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <FleetAssign />) },
        // { path: 'viaje', element: <Viaje /> },
        {
          path: 'panelControl',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_TORRE], null, <FleetPanelControl />),
        },
        { path: 'plans/new-plan', element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <NewPlan />) },
        {
          path: 'plans/edit-plan/:id',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <NewPlan />),
        },
        {
          path: 'plans/new-milestone',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <NewMilestone />),
        },
        {
          path: 'plans/edit-milestone/:idMilestone/:idPlan',
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <NewMilestone />),
        },
        {
          path: 'clientes',
          exact: true,
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_CLI, p?.CRE_CLI, p?.EDI_CLI, p?.VER_CLI, p?.ELI_CLI],
            null,
            <CustomerList />,
          ),
        },
        // { path: 'clientes/detalles/:id', exact: true, element: <CustomerDetails /> },
        {
          path: 'clientes/importar',
          exact: true,
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_CLI, p?.CRE_CLI, p?.EDI_CLI, p?.VER_CLI, p?.ELI_CLI],
            null,
            <ImportCustomersComponent />,
          ),
        },
        {
          path: 'clientes/perfil/:id',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_CLI], null, <CustomerProfile />),
        },
        {
          path: 'clientes/calificaciones',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_CLI], null, <Scores />),
        },
        {
          path: 'clientes/nuevo-cliente',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.CRE_CLI], null, <NewCustomer />),
        },
        {
          path: 'clientes/editar/:id',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.EDI_CLI], null, <NewCustomer />),
        },
        {
          path: 'favoritos',
          exact: true,
          element: useAG(
            f,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_FAV, p?.CRE_FAV, p?.EDI_FAV, p?.ELI_FAV, p?.VER_FAV],
            null,
            <FavoriteView />,
          ),
        },
        {
          path: 'empresa/perfil/:id',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP], null, <CompanyProfile />),
        },
        { path: '*', element: <Navigate to="/auth/login" /> },
      ],
    },
    {
      path: '/configuracion',
      element: useAG(null, null, null, <SettingLayout />),
      children: [
        { index: true, element: <Navigate to="usuario" replace /> },
        {
          path: 'usuario',
          exact: true,
          element: useAG(
            null,
            null,
            null,
            <Setting>
              <UserProfile />
            </Setting>,
          ),
        },
        {
          path: 'empresa',
          exact: true,
          element: useAG(
            null,
            [p?.ROOT, p?.CRE_EMP],
            null,
            <Setting>
              <ProfileCreate />
            </Setting>,
          ),
        },
        {
          path: 'roles',
          exact: true,
          element: useAG(
            null,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_ROL, p?.VER_ROL, p?.CRE_ROL, p?.EDI_ROL],
            null,
            <Setting>
              <RolesCreate />
            </Setting>,
          ),
        },
        {
          path: 'equipo',
          exact: true,
          element: useAG(
            null,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_USU, p?.VER_USU, p?.CRE_USU, p?.EDI_USU],
            null,
            <Setting>
              <AddUser />
            </Setting>,
          ),
        },
        {
          path: 'planes',
          exact: true,
          element: useAG(
            null,
            [p?.ROOT, p?.CRE_EMP],
            null,
            <Setting>
              <Plans />
            </Setting>,
          ),
        },
        {
          path: 'viajes',
          exact: true,
          element: useAG(
            null,
            [p?.ROOT, p?.CRE_EMP],
            null,
            <Setting>
              <HistoricTravels />
            </Setting>,
          ),
        },
        {
          path: 'cuenta',
          exact: true,
          element: useAG(
            null,
            [p?.ROOT, p?.CRE_EMP],
            null,
            <Setting>
              <Account />
            </Setting>,
          ),
        },
        /*       {
          path: ':type',
          exact: true,
          element: useAG(
            null,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_ROL, p?.CRE_ROL, p?.EDI_ROL, p?.VER_ROL, p?.ELI_ROL, p?.LIST_USU],
            null,
            <Setting />,
          ),
        }, */
        {
          path: 'pay/:id',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <SubscriptionPayment />),
        },
        {
          path: 'plan-detail',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <DetallePlanes />),
        },
        {
          path: 'contactanos',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <FormularioContacto />),
        },
        {
          path: 'exito',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <SuccessfulPayment />),
        },
        {
          path: 'rechazado',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <PaymentDeclined />),
        },
      ],
    },
    {
      path: '/carga',
      element: useAG(l, null, null, <CargoLayout />),
      children: [
        { index: true, element: <Navigate to={NavigateTo(auth, l)} replace /> },
        {
          path: 'inicio',
          exact: true,
          element: useAG(
            l,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_PED, p?.CRE_PED, p?.VER_PED],
            null,
            <CargoDashboard />,
          ),
        },
        {
          path: 'clientes',
          exact: true,
          element: useAG(
            l,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_CLI, p?.CRE_CLI, p?.EDI_CLI, p?.VER_CLI, p?.ELI_CLI],
            null,
            <CustomerList />,
          ),
        },
        // { path: 'clientes/detalles/:id', exact: true, element: <CustomerDetails /> },
        {
          path: 'clientes/importar',
          exact: true,
          element: useAG(
            l,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_CLI, p?.CRE_CLI, p?.EDI_CLI, p?.VER_CLI, p?.ELI_CLI],
            null,
            <ImportCustomersComponent />,
          ),
        },
        {
          path: 'clientes/perfil/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.VER_CLI], null, <CustomerProfile />),
        },
        {
          path: 'clientes/calificaciones',
          exact: true,
          element: useAG(f, [p?.ROOT, p?.CRE_EMP, p?.VER_CLI], null, <Scores />),
        },
        {
          path: 'clientes/nuevo-cliente',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.CRE_CLI], null, <NewCustomer />),
        },
        {
          path: 'clientes/editar/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.EDI_CLI], null, <NewCustomer />),
        },
        {
          path: 'pedidos/',
          exact: true,
          element: useAG(
            l,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_CAR,
              p?.ACE_CAR,
              p?.ASIG_CAR,
              p?.CANC_CAR,
              p?.CRE_CAR,
              p?.EDI_CAR,
              p?.ELIM_CAR,
            ],
            null,
            <PedidoTable />,
          ),
        },
        {
          path: 'pedidos/:estado',
          exact: true,
          element: useAG(
            l,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_CAR,
              p?.ACE_CAR,
              p?.ASIG_CAR,
              p?.CANC_CAR,
              p?.CRE_CAR,
              p?.EDI_CAR,
              p?.ELIM_CAR,
            ],
            null,
            <PedidoTable />,
          ),
        },
        {
          path: 'mapa',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.VER_CAR_MAPA], null, <MapView />),
        },
        {
          path: 'cargas/',
          exact: true,
          element: useAG(
            l,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_PED,
              p?.CRE_PED,
              p?.EDI_PED,
              p?.VER_PED,
              p?.ELI_PED,
              p?.COM_PED,
            ],
            null,
            <Pedido />,
          ),
        },
        {
          path: 'cargas/:estado',
          exact: true,
          element: useAG(
            l,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_PED,
              p?.CRE_PED,
              p?.EDI_PED,
              p?.VER_PED,
              p?.ELI_PED,
              p?.COM_PED,
            ],
            null,
            <Pedido />,
          ),
        },
        {
          path: 'tablero',
          exact: true,
          element: useAG(
            l,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_PED, p?.CRE_PED, p?.VER_PED],
            null,
            <CargoView />,
          ),
        },
        {
          path: 'kanban',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.LIST_CAR, p?.CRE_CAR], null, <KanbanView />),
        },
        {
          path: 'calificacion/:id',
          element: useAG(f, [p?.ROOT, p?.VER_VIA], null, <Score />)
        },
        {
          path: 'documentacion',
          element: useAG(
            l,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_DOC, p?.CRE_DOC, p?.EDI_DOC, p?.VER_DOC, p?.ELI_DOC],
            null,
            <Documents />,
          ),
        },
        {
          path: 'planificacion',
          element: useAG(
            l,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_PED, p?.VER_PED, p?.LIST_CAR],
            null,
            <Calendar />,
          ),
        },
        {
          path: 'ordenPedido/:id',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.LIST_CAR], null, <PedidosView />),
        },
        {
          path: 'ordenPedido/:id/:scroll',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.LIST_CAR], null, <PedidosView />),
        },
        {
          path: 'ordenCarga/:id',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.LIST_PED], null, <OrderView />),
        },
        {
          path: 'favoritos',
          exact: true,
          element: useAG(
            l,
            [p?.ROOT, p?.CRE_EMP, p?.LIST_FAV, p?.CRE_FAV, p?.EDI_FAV, p?.ELI_FAV, p?.VER_FAV],
            null,
            <FavoriteView />,
          ),
        },
        {
          path: 'favoritos/detalle/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.VER_FAV], null, <FavoriteDetails />),
        },
        {
          path: 'empresa/perfil/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <CompanyProfile />),
        },
        {
          path: 'checklist/:module',
          exact: true,
          element: useAG(
            l,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.LIST_CHKLIST,
              p?.VER_CHKLIST,
              p?.CRE_CHKLIST,
              p?.EDI_CHKLIST,
              p?.ELI_CHKLIST,
            ],
            null,
            <Checklist />,
          ),
        },
        {
          path: 'configuracion',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <SettingView />),
        },
        {
          path: 'configuracion/:type',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <SettingView />),
        },
        {
          path: 'kpi',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <KPIform />),
        },
        {
          path: 'kpi/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <KPIform />),
        },
        {
          path: 'load',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.CRE_PED], null, <Load />),
        },
        {
          path: 'load/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.EDI_PED], null, <Load />),
        },
        {
          path: 'order',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.CRE_CAR], null, <Order />),
        },
        {
          path: 'order/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.EDI_CAR], null, <Order />),
        },
        {
          path: 'order/:id/:step',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.EDI_CAR], null, <Order />),
        },
        {
          path: 'order/:id/:step/:assign',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.EDI_CAR, p?.ASIG_CAR], null, <Order />),
        },
        {
          path: 'documentacion/:type/:id',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.EDI_DOC], null, <VerDocumentacion />),
        },
        {
          path: 'armado-de-cargas',
          exact: true,
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.CRE_CAR, p?.EDI_CAR], null, <ArmadoDeCarga />),
        },
        {
          path: 'seguimiento/:id',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <Seguimiento />),
        },
        {
          path: 'panelControl',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.VER_TORRE], null, <PanelControl />),
        },
        {
          path: 'producto',
          element: useAG(
            l,
            [
              p?.ROOT,
              p?.CRE_EMP,
              p?.CRE_PRODU,
              p?.LIST_PRODU,
              p?.VER_PRODU,
              p?.EDI_PRODU,
              p?.ELI_PRODU,
            ],
            null,
            <ProductsTable />,
          ),
        },
        {
          path: 'producto/new-producto',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.CRE_PRODU], null, <Products />),
        },
        {
          path: 'producto/editar-producto/:id',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP, p?.EDI_PRODU], null, <Products />),
        },
        { path: 'tarifario', element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <Tarifario />) },
        {
          path: 'tarifario/nueva-tarifa',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <NewTarifa />),
        },
        {
          path: 'tarifario/editar-tarifa/:id',
          element: useAG(l, [p?.ROOT, p?.CRE_EMP], null, <EditTarifa />),
        },
        { path: '*', element: <Navigate to="/auth/login" /> },
      ],
    },
    {
      path: '/follow',
      element: useAG(null, null, null, <FollowLayout />),
      children: [
        { path: 'dashboard', exact: true, element: <FollowDashboard /> },
        { path: '*', element: <Navigate to="/auth/login" /> },
      ],
    },
    {
      path: '/mantenimiento',
      element: useAG(null, null, null, <MaintenanceLayout />),
      children: [
        { index: true, element: <Navigate to="inicio" replace /> },
        {
          path: 'inicio',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenanceDashboard />),
        },
        {
          path: 'new',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenanceOrderCreate />),
        },
        {
          path: 'edit/:id',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenanceOrderCreate />),
        },
        {
          path: 'history',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenanceHistory />),
        },
        {
          path: 'history/:id',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenanceHistory />),
        },
        {
          path: 'vehicle-report',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <VehicleReport />),
        },
        {
          path: 'basic-report',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <BasicReport />),
        },
        {
          path: 'plan/details/:id',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenancePlansDetails />),
        },
        {
          path: 'plan/edit/:id',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenancePlansEdit />),
        },
        {
          path: 'plan/create',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenancePlansCreate />),
        },
        {
          path: 'plan/assignments/:id/:dominio',
          exact: true,
          element: useAG(null, [p?.ROOT, p?.CRE_EMP], null, <MaintenancePlans />),
        },
        { path: '*', element: <Navigate to="/auth/login" /> },
      ],
    },
    {
      path: '/resources',
      element: useAG(null, null, null, <ResourcesLayout />),
      children: [
        { path: 'dashboard', exact: true, element: <ResourcesDashboard /> },
        { path: '*', element: <Navigate to="/auth/login" /> },
      ],
    },
    {
      path: '/workflow',
      element: useAG(w, null, null, <WorkflowLayout />),
      children: [
        { index: true, element: <Navigate to="inicio" replace /> },
        // { path: 'asignar/:id', element:useAG(w, null, null, <Assignment />) },

        { path: 'inicio', exact: true, element: useAG(w, null, null, <WorkflowDashboard />) },
        { path: 'procesos', element: useAG(w, null, null, <Processes />) },
        {
          path: 'proceso/asignar/:id',
          exact: true,
          element: useAG(w, [p?.ROOT, p?.CRE_EMP, p?.ASIG_PROC], null, <Assignment />),
        },

        {
          path: 'proceso-documental/crear',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.CRE_PROC],
            null,
            <WorkflowProcesoDocumental />,
          ),
        },
        {
          path: 'proceso-documental/:id',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.EDI_PROC],
            null,
            <WorkflowProcesoDocumental />,
          ),
        },
        {
          path: 'proceso-documental/:id/:mode',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.EDI_PROC],
            null,
            <WorkflowProcesoDocumental />,
          ),
        },
        //  { path: 'proceso-documental/asignar/:id', exact: true, element: useAG( w, [p?.ROOT, p?.CRE_EMP, p?.ASIG_PROC],null, <WorkflowAsignarProcesoDocumental />)},

        { path: 'procedimiento', exact: true, element: useAG(w, null, null, <Procedures />) },
        {
          path: 'procedimiento/crear',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.CRE_PROCED],
            null,
            <WorkflowProcedimientos esProcedimiento />,
          ),
        },
        {
          path: 'procedimiento/:id',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.EDI_PROCED],
            null,
            <WorkflowProcedimientos esProcedimiento />,
          ),
        },
        {
          path: 'procedimiento/:id/:mode',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.EDI_PROCED],
            null,
            <WorkflowProcedimientos esProcedimiento />,
          ),
        },
        {
          path: 'procedimiento/asignar/:id',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.ASIG_PROCED],
            null,
            <Assignment esProcedimiento />,
          ),
        },
        // { path: 'procedimiento/asignar/:id', exact: true, element: useAG(w,[p?.ROOT, p?.CRE_EMP, p?.ASIG_PROCED],null,<WorkflowAsignarProcedimiento esProcedimiento />) },

        {
          path: 'arbol-de-procedimientos',
          exact: true,
          element: useAG(w, null, null, <WorkflowProcedimientosAdmin />),
        },

        {
          path: 'plantillas',
          children: [
            {
              path: 'admin',
              element: useAG(w, null, null, <WorkflowPlantillasAdmin />),
            },
            { path: 'ver/:id', exact: true, element: useAG(w, null, null, <WorkflowForm />) },
            {
              path: 'crear',
              exact: true,
              element: useAG(w, [p?.ROOT, p?.CRE_EMP, p?.CRE_FORM], null, <WorkflowPlantillas />),
            },
            {
              path: 'crear/:idCarpeta',
              exact: true,
              element: useAG(w, [p?.ROOT, p?.CRE_EMP, p?.CRE_FORM], null, <WorkflowPlantillas />),
            },
            {
              path: 'editar/:id',
              exact: true,
              element: useAG(w, null, null, <WorkflowPlantillas />),
            },
          ],
        },

        // {
        //   path: 'administracion-documentos2',
        //   exact: true,
        //   element: useAG(
        //     w,
        //     [p?.ROOT, p?.CRE_EMP, p?.VER_DOC_WF, p?.EDI_DOC_WF],
        //     null,
        //     <WorkflowDocumentsAdmin esDocumentacion />,
        //   ),
        // },
        {
          path: 'administracion-documentos',
          exact: true,
          element: useAG(
            w,
            [p?.ROOT, p?.CRE_EMP, p?.VER_DOC_WF, p?.EDI_DOC_WF],
            null,
            <DocumentManagement />,
          ),
        },

        // {
        //   path: 'administracion-permisos',
        //   exact: true,
        //   element: useAG(w, null, null, <WorkflowPermissionsAdmin />),
        // },

        // No se usa, Eliminar todas las carpetas asociadas a este documento que no se usan
        {
          path: 'documentacion/admin',
          exact: true,
          element: useAG(w, null, null, <WorkflowDocumentacion />),
        },

        // Verificar donde se usa y mejorar las url
        {
          path: 'formulario/:id/:mode',
          exact: true,
          element: useAG(w, null, null, <WorkflowForm />),
        },

        { path: '*', element: <Navigate to="/auth/login" /> },
      ],
    },

    {
      path: 'marketplace',
      element: useAG(m, null, null, <MarketplaceLayout />),
      children: [
        { index: true, element: <Navigate to="inicio" replace /> },
        {
          path: 'inicio',
          exact: true,
          element: useAG(m, null, null, <MarketplaceDashboard />),
        },
        {
          path: 'inicio/:id',
          exact: true,
          element: useAG(m, null, null, <MarketplaceDashboard />),
        },
        {
          path: 'inicio/orden',
          exact: true,
          element: useAG(m, null, null, <MarketplaceOrderView />),
        },
        {
          path: 'orden/:id',
          exact: true,
          element: useAG(m, null, null, <MarketplaceOrderView />),
        },
        {
          path: '/detalle/:id',
          exact: true,
          element: useAG(m, null, null, <MarketplaceOrderView />),
        },
        // { path: 'product-detail/:productId', element: <ProductDetail /> },
        // { path: 'alertas', element: <MarketplaceAlertas /> },
        {
          path: 'publicaciones',
          element: useAG(m, null, null, <MarketplaceMisCargas showMyLoads />),
        },
        {
          path: 'envista',
          element: useAG(m, null, null, <MarketplaceMisCargas showOthers />),
        },
        {
          path: 'customers/profile/:id',
          exact: true,
          element: useAG(m, null, null, <MarketplaceCustomerProfile />),
        },
        {
          path: 'contaco/:id',
          exact: true,
          element: useAG(m, null, null, <Contacto />),
        },
      ],
    },
    {
      path: '/error',
      element: <BlankLayout />,
      children: [{ path: '/:numero', exact: true, element: <Error /> }],
    },
    {
      path: '/seguimiento/:travelid/:clientid',
      exact: true,
      element: <ViewWithoutLogin />,
    },
    {
      path: '/marketplace/:url',
      exact: true,
      element: <PrivateMarketplace />,
    },
    {
      path: '/marketplace/detalle/:id',
      exact: true,
      element: <PrivateMarketplace />,
    },
    {
      path: '/pay/:id',
      exact: true,
      element: <SubscriptionPayment />,
    },
    {
      path: '/follow-help-center',
      exact: true,
      element: <HelpCenterLayout />,
      children: [
        { path: '/', element: <HelpCenter /> },
        { path: 'elementor-4071', element: <KnowPlatform /> },
        { path: 'crea-tu-cuenta-como-nueva-empresa', element: <CreateCompany /> },
        { path: 'editar-datos-de-la-empresa', element: <EditCompanyData /> },
        { path: 'crea-tu-cuenta-para-adherirte-a-una-empresa-existente', element: <JoinCompany /> },
        { path: 'preguntas-frecuentes', element: <FrequentQuestions /> },
        { path: 'dador-de-carga-preguntas-frecuentes', element: <ChargeGiverFQ /> },
        { path: 'preguntas-frecuentes-transportistas', element: <TransportersFQ /> },
        { path: 'tutoriales-y-videos', element: <TutorialsAndVideos /> },
        { path: 'tutoriales-y-videos-dador', element: <TutorialsAndVideosGiver /> },
        { path: 'tutoriales-y-videos-transportistas', element: <TutorialsAndVideosCarriers /> },
        { path: 'informacion-general-de-la-cuenta', element: <AccountInfo /> },
      ],
    },
    {
      path: '/bahia',
      element: <BayLayout />,
      children: [
        { index: true, element: <BayDashboard /> },
        {
          path: 'inicio',
          exact: true,
          element: <BayDashboard />,
        },
        {
          path: 'bahia',
          exact: true,
          element: <ListBay />,
        },
        {
          path: 'bahia/nueva-bahia',
          exact: true,
          element: <Bay />,
        },
        {
          path: 'bahia/editar/:id',
          exact: true,
          element: <Bay />,
        },

        {
          path: 'muelle',
          exact: true,
          element: <ListDock />,
        },
        {
          path: 'muelle/editar/:id',
          exact: true,
          element: <Dock />,
        },

        {
          path: 'muelle/nuevo-muelle',
          exact: true,
          element: <Dock />,
        },

        {
          path: 'turnos',
          exact: true,
          element: <Shifts />,
        },
        {
          path: 'planificacion',
          exact: true,
          element: <Scheduling />,
        },
        {
          path: 'zona-de-espera',
          exact: true,
          element: <ABM />,
        },
        {
          path: 'zona-de-espera/nueva-zona',
          exact: true,
          element: <CreateABM />,
        },
        {
          path: 'zona-de-espera/editar-zona/:id',
          exact: true,
          element: <CreateABM />,
        },
        {
          path: '/pendientes',
          exact: true,
          element: <Pendings />,
        },
      ],
    },
    {
      path: '/torre-de-control',
      element: <MapLayout />,
      children: [
        { index: true, element: <Navigate to={NavigateTo(auth, f)} replace /> },
        {
          path: 'inicio',
          exact: true,
          element: <MapOT />,
        },
        {
          path: 'kpi',
          exact:true,
          element: <LoadsPanelControl />
        },
        {
          path: 'incidentes',
          exact: true,
          element: <IncidentsView />,
        },
        {
          path: 'incidentes/nuevo-incidente',
          exact: true,
          element: <NewIncident />,
        },
        {
          path: 'incidentes/editar/:id',
          exact: true,
          element: <NewIncident />,
        },
        {
          path: 'panelControl',
          exact: true,
          element: <KPIPanel />
        },
        {
          path: 'incidentes/detalle/:id',
          exact: true,
          element: <ViewDetailIncident />
        },

      ],
    },
    {
      path: '/backoffice',
      element: <BackofficeLayout />,
      children: [
        { index: true, element: useAG(null, [p?.ROOT], null, <companiesTable />) },
        {
          path: 'empresas',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <CompaniesTable />),
          element: <CompaniesTable />,
        },
        {
          path: 'usuarios',
          exact: true,
          //  element: useAG(null, [p?.ROOT], null, <UsersTable />),
          element: <UsersTable />,
        },
        {
          path: 'consultas',
          exact: true,
          // element:  useAG(null, [p?.ROOT], null, <RolesTable/>),
          element: <QueriesTable />,
        },
        {
          path: 'parametros-acceso/roles',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <RolesTable/>),
          element: <RolesTable />,
        },
        {
          path: 'parametros-acceso/permisos',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <RolesTable/>),
          element: <PermissionsTable />,
        },
        {
          path: 'parametros-acceso/promociones',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <RolesTable/>),
          element: <PromotionsTable />,
        },
        {
          path: 'parametros-acceso/suscripciones',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <RolesTable/>),
          element: <SubscriptionsTable />,
        },
        {
          path: 'parametros-carga/tiposCarga',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <LoadTypesTable/>),
          element: <LoadTypesTable />,
        },
        {
          path: 'parametros-carga/tiposEmbalaje',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <PackagingTypesTable/>),
          element: <PackagingTypesTable />,
        },
        {
          path: 'parametros-carga/tiposPallet',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <PalletTypesTable/>),
          element: <PalletTypesTable />,
        },
        {
          path: 'parametros-generales/paises',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <CountriesTable/>),
          element: <CountriesTable />,
        },
        {
          path: 'parametros-generales/lugares',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <PlacesTable/>),
          element: <PlacesTable />,
        },
        {
          path: 'parametros-generales/documentos',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <DocumentTypesTable/>),
          element: <DocumentTypesTable />,
        },
        {
          path: 'parametros-generales/pago',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <PaymentsTable/>),
          element: <PaymentsTable />,
        },
        {
          path: 'parametros-flota/marcas',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <BrandsTable/>),
          element: <BrandsTable />,
        },
        {
          path: 'parametros-flota/tiposCamion',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <TruckTypes/>),
          element: <TruckTypes />,
        },
        {
          path: 'parametros-flota/tipoBitren',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <BitrenTypes/>),
          element: <BitrenTypes />,
        },
        {
          path: 'parametros-flota/tiposRemolque',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <TrailerTypes/>),
          element: <TrailerTypes />,
        },
        {
          path: 'parametros-flota/tiposContenedor',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <ContainerTypes/>),
          element: <ContainerTypes />,
        },
        {
          path: 'parametros-flota/accesorios',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <AccesoriesTable/>),
          element: <AccesoriesTable />,
        },
        {
          path: 'parametros-flota/caracteristicas',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <FeaturesTable/>),
          element: <FeaturesTable />,
        },
        {
          path: 'parametros-flota/tiposUltimaMilla',
          exact: true,
          // element: useAG(null, [p?.ROOT], null, <LastMileTypesTable/>),
          element: <LastMileTypesTable />,
        },
      ],
    },
  ];

  return router;
};

export default Router;

import RefreshIcon from '@mui/icons-material/Refresh';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Container } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllActivosKPI } from '../../api/activos';
import { getListLoad } from '../../api/cargo';
import { getCompany } from '../../api/company';
import { getListExpense } from '../../api/expenses';
import { getOrderList } from '../../api/order';
import { getListTravels } from '../../api/viaje';
import PageContainer from '../../components/container/PageContainer';
import ChartBarWithAverage from '../../components/controlPanels/chartBarWhitAverage/ChartBarWithAverage';
import GastosChart from '../../components/controlPanels/GastosChart/GastosChart';
import SimpleChartBar from '../../components/controlPanels/simpleChartBar/SimpleChartBar';
import { expensesTotal, maxData } from '../../components/controlPanels/utils/handleDataToTimeFrame';
import ControlPanel from '../../components/controlPanels/withTimeframe/ControlPanel';
import DelayedControlPanel from '../../components/controlPanels/withTimeframe/DelayedControlPanel';
import { modules } from '../../constants/permissions';
import { sxTableButtons } from '../../constants/styleTableButton';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import useAuth from '../../hooks/useAuth';
import Breadcrumb from '../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import LoadChart from '../cargo/panelControl/LoadChart';
import LoadsPerTime from '../cargo/panelControl/LoadsPerTime';
import Spinner from '../spinner/Spinner';

const KPIPanel = () => {
  const auth = useAuth();
  const [t] = useTranslation();
  const theme = useTheme();
  const {
    mxTableButtons,
    myTableButtons,
    widthScreen,
    mtTypographyTableButtons,
    typographyVariantButton,
  } = useContext(ScreenSizeContext);
  const [firstSession, setFirstSession] = useState(null);
  const [loads, setLoads] = useState(null);
  const [orders, setOrders] = useState(null);
  const [travels, setTravels] = useState(null);
  const [actives, setActives] = useState(null);
  const [dataExpensePerTravel, setDataExpensePerTravel] = useState(null);
  const [dataGastos, setDataGastos] = useState([]);
  const [dataGastosMonth, setDataGastosMonth] = useState(null);
  const [loadsStatus, setLoadsStatus] = useState(null);
  const [enableView, setEnableView] = useState(false);
  const [error, setError] = useState(false);

  const BCrumb = [
    {
      to: '/torre-de-control/inicio',
      title: 'Panel de Control',
    },
    {
      title: t('Control-panel'),
    },
  ];

  const toActiveData = (data, activeType) => {
    const activesList = [];
    data
      ?.filter((d) => d.tipoActivo === activeType)
      .forEach((e) => {
        const { estado } = e;
        let estadoObj = activesList.find((item) => item.estado === estado);
        if (!estadoObj) {
          estadoObj = { estado, cantidad: 0 };
          activesList.push(estadoObj);
        }
        estadoObj.cantidad += 1;
      });
    return activesList;
  };

  const fetchLoadData = async () => {
    try {
      const [respFirstSession, respLoad, respTypeLoads, respOrders, respKpiCreated] =
        await Promise.all([
          getCompany(auth?.authenticatedData?.idEmpresa),
          getListLoad(),
          getOrderList(),
          // getKPIOrders(),
          // getCreatedKPI(),
        ]);
      const toStatusData = Object.keys(respTypeLoads.data).map((key) => {
        return {
          estado: key,
          cantidad: respTypeLoads.data[key].length,
        };
      });
      setLoadsStatus(toStatusData);
      setFirstSession(respFirstSession.data.primeraSesion);
      setLoads(respLoad.data);
      setOrders(respOrders?.data);
      // setDataCreatedKpi(respKpiCreated.data.kpi);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setEnableView(true);
    }
  };

  const fetchFleetData = async () => {
    try {
      const respFirstSession = await getCompany(auth?.authenticatedData.idEmpresa);
      const respTravels = await getListTravels();
      const respExpenses = await getListExpense();
      const respActives = await getAllActivosKPI();

      const expesePerTravels = maxData(respExpenses.data, respFirstSession.data.primeraSesion);
      const resultado = expensesTotal(respExpenses.data);
      setDataGastos(resultado);
      setFirstSession(respFirstSession.data.primeraSesion);
      setTravels(respTravels?.data || null);
      setDataExpensePerTravel(expesePerTravels);
      setActives(respActives?.data || null);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setEnableView(false);
    }
  };

  const syncUpKpi = async () => {
    try {
      setEnableView(false);
      // await updateKpi();
    } catch (e) {
      console.log('error', e);
    } finally {
      fetchLoadData();
      fetchFleetData();
    }
  };

  useEffect(() => {
    fetchLoadData();
    fetchFleetData();
  }, []);
  
  if (!enableView) {
    return (
      <Container maxWidth={false}>
        <Spinner />
      </Container>
    );
  }


  return (
    <PageContainer title="KPI Torre Control">
      <Breadcrumb items={BCrumb} />
      <Button
        sx={{ ...sxTableButtons, mx: mxTableButtons, my: myTableButtons }}
        variant="contained"
        color="primary"
        size="small"
        endIcon={
          <RefreshIcon
            style={{ fontSize: '24px' }}
            sx={{ mr: widthScreen > 450 ? 0.5 : 0.4, ml: widthScreen > 420 ? 0 : -0.6 }}
          />
        }
        onClick={syncUpKpi}
      >
        <Typography
          variant={typographyVariantButton}
          sx={{ ml: widthScreen > 420 ? 0 : -0.5, mt: mtTypographyTableButtons }}
        >
          {t('Update Charts')}
        </Typography>
      </Button>
      {error ? (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '50vh',
          }}
        >
          <WifiOffIcon
            fontSize="large"
            sx={{ width: '10rem', height: '10rem', color: theme.palette.primary.main }}
          />
          <Typography variant="h3">{t('server error')}</Typography>
          <Typography variant="h4" textAlign="center" py={0.5}>
            {t('mjs-server error')}
          </Typography>
          <Typography variant="h4" textAlign="center" py={0.5}>
            {t('mjs2-server error')}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Divider sx={{ height: 10, backgroundColor: 'orange', my: 4, mx: 2 }} />
            </Grid>
            {loads?.length > 0 && (
              <Grid item md={6} sm={12} xs={12}>
                <ControlPanel
                  title={t('Loads-and-amounts-by-periods')}
                  data={loads}
                  firstSession={firstSession}
                  module={modules?.load}
                />
              </Grid>
            )}
            {orders && (
              <Grid item md={6} sm={12} xs={12}>
                <LoadsPerTime
                  title="Pedidos entregados"
                  data={orders}
                  firstSession={firstSession}
                  module={modules.load}
                />
              </Grid>
            )}
            <Grid item md={12} sm={12} xs={12}>
              <LoadChart
                title={t('Total-loads-by-state')}
                data={loadsStatus}
                xDataKey="estado"
                barDataKey="cantidad"
                strToolstip={t('Plans.Loads')}
                borderBarColor="#B62900"
                barColor="#E77E00"
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Divider sx={{ height: 10, backgroundColor: 'orange', my: 4, mx: 2 }} />
            </Grid>
            {travels?.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <ControlPanel
                  title={t('Travels-and-amounts-by-periods')}
                  data={travels}
                  firstSession={firstSession}
                  module={modules.fleet}
                />
              </Grid>
            )}

            {dataGastos && (
              <Grid item xs={12} sm={12} md={6}>
                <ChartBarWithAverage
                  title={t('Average-expense-per-travel')}
                  data={dataExpensePerTravel}
                  xDataKey="date"
                  barDataKey="monto"
                  barDataKey2="cantidad"
                  lineDataKey="promedio"
                  strToolstip1={t('Total spending for the month')}
                  strToolstip2={t('Average-expense-per-travel')}
                  strToolstip3={t('Number of trips')}
                  barColor="#FFB45B"
                  borderBarColor="#CF7100"
                  lineColor="#005E6F"
                  setDataGastosMonth={setDataGastosMonth}
                  dataGastos={dataGastos}
                />
              </Grid>
            )}

            {dataGastos?.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <GastosChart
                  title={t('Total-expense-by-type')}
                  data={dataGastos}
                  dataGastosMonth={dataGastosMonth}
                  xDataKey="tipoDeGasto"
                  barDataKey="monto"
                  strToolstip={t('Total-expense')}
                  borderBarColor="#B62900"
                  barColor="#E77E00"
                  setDataGastosMonth={setDataGastosMonth}
                />
              </Grid>
            )}

            {actives?.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <SimpleChartBar
                  title={t('Total-drivers-by-state')}
                  data={toActiveData(actives, 'chofer')}
                  xDataKey="estado"
                  barDataKey="cantidad"
                  strToolstip={t('Total-drivers')}
                  borderBarColor="#007892"
                  barColor="#43B3CB"
                  xAxisName={t('Availability')}
                  yAxisName={t('Number of drivers')}
                />
              </Grid>
            )}

            {actives?.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <SimpleChartBar
                  title={t('Total-vehicles-by-state')}
                  data={toActiveData(actives, 'vehiculo')}
                  xDataKey="estado"
                  barDataKey="cantidad"
                  strToolstip={t('Total-vehicles')}
                  borderBarColor="#00033F"
                  barColor="#5B63FF"
                  xAxisName={t('Availability')}
                  yAxisName={t('Number of vehicles')}
                />
              </Grid>
            )}

            {actives?.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <SimpleChartBar
                  title={t('Total-trailers-by-state')}
                  data={toActiveData(actives, 'remolque')}
                  xDataKey="estado"
                  barDataKey="cantidad"
                  strToolstip={t('Total-trailers')}
                  borderBarColor="#004E0F"
                  barColor="#49C661"
                  xAxisName={t('Availability')}
                  yAxisName={t('Number of trailers')}
                />
              </Grid>
            )}

            {travels?.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <DelayedControlPanel
                  title="Viajes demorados"
                  data={travels}
                  firstSession={firstSession}
                  module={modules.fleet}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </PageContainer>
  );
};

export default KPIPanel;
